import {
  required,
  minLength,
  maxLength,
  alpha,
  helpers
} from 'vuelidate/lib/validators';

import { textareaSpecials, onlyNumbers, onlyText } from '../funciones';
import { inputColor } from '../validacionEspeciales';

const alphaSpecials = (value) => /^[a-zA-Z \u00f1\u00d1,.\-\/\$\&\(\)]+$/g.test(value);
const alphaNumCustom = (value) => /^[\w \u00f1\u00d1\-]*$/g.test(value);
const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);
const verifyNumber = (value) => {
  return value === "0,00" ? false : true;
}
const IMEINotReq = (value) => !helpers.req(value) || onlyNumbers(value);
const manufactererNotReq = (value) => !helpers.req(value) || onlyText(value);
const resolution = (value) => /^[0-9.*]*$/.test(value);

export default () => {
  return {
    device: {
      DeviceName: { required, alphaNumCustom, maxLength: maxLength(50) },
      UniqueDeviceId: { required, minLength: minLength(16) },
      Cod: { required, alphaNumCustom, maxLength: maxLength(10) },
      TpDeviceId: { required},
      OperatingSystem: {alphaNumCustom, maxLength: maxLength(20) },
      BrandId: { required},
      ModelName: { alphaNumCustom, maxLength: maxLength(20) },
      Manufacturer: { manufactererNotReq },
      MemoryInternal: {  },
      StorageCapacity: {  },
      LevelPreferenceId: { required },
      Heigth: {  },
      Width: {  },
      Weigth: {  },
      Depth: {  },
      ScreenSize: {  },
      ResolutionScreen: {  resolution },
      Imei: { IMEINotReq, maxLength: maxLength(15) },
      DeviceStatus: { required },
      Color: { inputColor },
      Observation: { TextareaNotReq, maxLength: maxLength(240) },
    },
    type: {required},
    brand: {required},
    condition: { required },
    level: { required },
  }
}